import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SpeakerCard from "./speakerCard"
import Link from "./link"
import RenderHtml from "./renderHtml"

import style from "./closedBeta.module.css"
import alternatingStyle from "./alternatingList.module.css"

const ClosedBeta = ({ date }) => {
	const beta = useBetas().find(beta => beta.frontmatter.date === date)
	return (
		<div className={alternatingStyle.container} key={beta.frontmatter.date}>
			<div className={alternatingStyle.speakers}>{images(beta.frontmatter.speakers)}</div>
			<div className={alternatingStyle.event}>{event(beta)}</div>
		</div>
	)
}

function images(speakers) {
	if (!speakers || speakers.length === 0) return

	return speakers.map(speaker => (
		<SpeakerCard
			speakerSlug={speaker.frontmatter.slug}
			size="small"
			linkImage
			linkName
			showName
			showTalks
			className={alternatingStyle.image}
			key={speaker.frontmatter.slug}
		/>
	))
}

const event = beta => {
	return (
		<React.Fragment>
			<h2 className={alternatingStyle.title}>{version(beta.frontmatter.date)}</h2>
			<dl className={style.coordinates}>
				<dt>When?</dt>
				<dd>{eventDate(beta.frontmatter.date)}</dd>
				<dt>Where?</dt>
				<dd>{beta.frontmatter.url ? (<Link to={beta.frontmatter.url}>hopin.to</Link>) : (<>hopin.to</>)}</dd>
			</dl>
			<p className={alternatingStyle.teaser}>
				<RenderHtml withAst={beta}/>
			</p>
		</React.Fragment>
	)
}

const version = dateString => {
	const date = new Date(dateString)
	return `v${date.getUTCMonth() + 1}.${date.getUTCDate()}`
}

const eventDate = dateString => {
	const date = new Date(dateString)
	return (
		<span className={style.date}>
			<span className={style.weekday}>{weekday(date)}</span>,{" "}
			<span className={style.month}>{month(date)}</span>{" "}
			<span className={style.day}>{day(date)}</span>,{" "}
			<span className={style.time}>{time(date, "UTC")} ({time(date, "CEST")})</span>
		</span>
	)
}

const time = (date, timezone) => {
	let minutes = date.getUTCMinutes()
	if (minutes < 10) minutes = "0" + minutes
	const hours = timezone === "CEST" ? date.getUTCHours() + 2 : date.getUTCHours()
	return `${hours}:${minutes} ${timezone}`
}

const weekday = date =>
	["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date.getUTCDay()]

const month = date => {
	switch (date.getUTCMonth()) {
		case 0:
			return `January`
		case 1:
			return `February`
		case 2:
			return `March`
		case 3:
			return `April`
		case 4:
			return `May`
		case 5:
			return `June`
		case 6:
			return `July`
		case 7:
			return `August`
		case 8:
			return `September`
		case 9:
			return `October`
		case 10:
			return `November`
		case 11:
			return `December`
		default:
			throw new Error("New month discovered!")
	}
}

const day = date => {
	switch (date.getUTCDate()) {
		case 1:
			return `1st`
		case 2:
			return `2nd`
		case 3:
			return `3rd`
		case 21:
			return `21st`
		case 22:
			return `22nd`
		case 23:
			return `23rd`
		case 31:
			return `31st`
		default:
			return date.getDate() + "th"
	}
}

function useBetas() {
	return useStaticQuery(graphql`
		{
			betas: allMarkdownRemark(
				filter: { fields: { collection: { eq: "pre" } } }
				sort: { fields: frontmatter___date, order: ASC }
			) {
				nodes {
					frontmatter {
						date
						url
						speakers {
							fields {
								path
							}
							frontmatter {
								name
								slug
								bio
								...Social
							}
						}
					}
					htmlAst
				}
			}
		}
	`).betas.nodes
}

export default ClosedBeta
